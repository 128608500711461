.tab-page {
  margin: 0 auto;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  padding:0 2rem ;
}


.tab-buttons {
  border-radius: 8px;
  max-width: 1100px;
  margin: 0 auto;
  margin-top: 50px;
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  background-color: #CECDCD;
  padding: 8px 0px;
}

.tab-buttons button {
  padding: 8px 15px;
  font-size: 16px;
  border-radius: 20px;
  cursor: pointer;
  background-color: white;
  border: none;
  display: flex;
}

.tab-buttons button.active {
  background-color: white;
  border: 2px solid  rgba(224, 149, 35, 1);
  /* background: linear-gradient(0deg, rgba(224, 149, 35, 0.5), rgba(224, 149, 35, 0.5)),linear-gradient(0deg, #CECDCD, #CECDCD); */

}

.tab-content {
  margin: 0 auto;
  padding: 20px;
}


.current_tab{
  padding: 8px 15px;
  font-size: 16px;
  border-radius: 20px;
  cursor: pointer;
  background-color: white;
  border: none;
  border: 2px solid  rgba(224, 149, 35, 1);
}

.css-y8ay40-MuiTableCell-root {
  background-color: unset !important;
  font-family: unset !important;
}


/* Responsive Styles */

@media (max-width: 768px) {
  .tab-buttons {
    flex-direction: column;
    align-items: center;
  }

  .tab-buttons button {
    margin-bottom: 10px;
  }
}

/* UserProfile.css */
.user-container{
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0px 20px;

}

.user-profile {
  padding: 20px;
  width: 70%;
  margin: 0 auto;

}

.user-profile.editing {
  background-color: #fff;
}

.profile-header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.profile-image {
  margin-right: 20px;
  width: max-content;
}

.profile-image img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.3s;
  position: relative;

  &:hover {
    transform: scale(1.05);
  }

}
/* .edit-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  font-size: 18px;
  cursor: pointer;
} */

.profile-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.user-name,
.email-address,
.password {
  margin-bottom: 10px;

}

.profile-info input {
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 1px solid #ccc;
  font-family:'Manrope', 'Inter', sans-serif;
  font-size: 16px;
  transition: border-color 0.3s;
}

.profile-info input:focus {
  outline: none;
}

.edit-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 18px;

}

.save-button {
  color: #28a745;
  margin-right: 10px;
  cursor: pointer;
}

.edit-button {
  color: #007bff;
  cursor: pointer;
}
.user-status {
  margin-top: 20px;

}

.user-status p {
  margin: 5px 0;
}

.actions {
  margin-top: 20px;
}

.actions button {
  padding: 10px 30px;
  margin-right: 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}
.upgrade-button {
  background-color: red;
  border: none;
  color: #fff;
  margin-bottom: 1rem;
}

.subscribe-button {
  border: 2px solid rgba(224, 149, 35, 1);
  background: white;
}

/* Add these styles to your CSS file */

.profile-dummy {
  width: 100px;
  height: 100px;
  background-color: #ccc;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #fff;
}

/* Alteranative page */
.search-alt {
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
}

.search-alt input[type='search'] {
  width: 100%;
  border: 1px solid #ccc; /* Border color */
  border-radius: 1rem;
  padding: 0.6rem 1rem;
  transition: border-color 0.3s ease-in-out; /* Smooth transition for border color */
}

/* Hover effect */
.search-alt input[type='search']:hover {
  border-color: #999; /* Change border color on hover */
}

/* Focus effect */
.search-alt input[type='search']:focus {
  outline: none;
  border-color: rgba(224, 149, 35, 1); /* Border color on focus */
}

/* Placeholder */
.search-alt input[type='search']::placeholder {
  color: #999; /* Placeholder color */
  font-family:'Manrope', 'Inter', sans-serif;
}

/* Clear button */
.search-alt input[type='search']::-webkit-search-cancel-button {
  height: 1em;
  width: 1em;
  border-radius: 50%;
  background-color: rgba(224, 149, 35, 1);
  position: relative;
  right: 8px;
  cursor: pointer;
}

/* Clear button hover effect */
.search-alt input[type='search']::-webkit-search-cancel-button:hover {
  background-color: #999; /* Change background color on hover */
}

.alt-food-container{
  width: 100vw;
}


.alt-component{
  width: 90%;
  margin: 0 auto;
}

.search_card{
  margin-top: 2rem;
}

.alt-food-container .food{
  min-width: 120px;
  display: flex;
  align-items: center;
  border: 1px solid #999;
  justify-content: center;
  padding: 2px 5px;
  gap: 0.5rem;
  border-radius:  1rem;
  max-width:max-content;
}

.alt-food-container .food div{
  width: 30px;
  height: 30px;
}

.alt-food-container .food div img{
  width: 100%;
}

.food-card-details{
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  font-size: 0.85rem;
  width: 90%;
  max-width: 95%;
  line-height: 1.3rem;
  word-spacing: 0.25rem;
  height: 150px;
  max-height: 200px;
  overflow:hidden;
}

.show-more{
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  font-size: 0.85rem;
  width: 90%;
  max-width: 95%;
  line-height: 1.3rem;
  word-spacing: 0.25rem;
  height: auto;
  /* overflow:hidden; */
}

.food-card-details div:first-of-type, .show-more div:first-of-type{
  width: 40%;
  text-align: left;
}

.food-card-details div:last-of-type, .show-more div:last-of-type{
  width: 30%;
}

.alt-food-container h6{
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  font-weight: 400;
  text-decoration: underline;
}

.search_card_btn{
  padding: 10px 20px;
  background-color: rgba(255, 186, 8, 1);
  color: white;
  font-weight: 600;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 0.5rem;
}

#alt-heading{
  font-family: 'Inter', sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  text-decoration: none;
  margin-block: 3rem;
}

#alt-card{
  margin-top: 0px;
}

#group{
  font-weight: 700;
  margin-bottom: 5px;
}
#group span{
  font-weight: 400;
}

.line-break {
  margin-top: 1rem;
  width: 100%;
  height: 2px;
  overflow: hidden;
  position: relative;
}

.line-break::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, transparent 0%, #ff143b 25%, #ff8c00 50%, #137913 75%, transparent 100%);
  animation: slide 2s linear infinite;
}

@keyframes slide {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
