.signup-container, .login-page {
    /* margin-top: 70px; */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100vh;
}

.signup-content, .login-content {
    width: 100%;
    max-width: 400px;
    padding: 40px;
}

.signup-content h2, .login-content h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.signup-content p, .login-content p{
    font-size: 14px;
    color: #888;
    margin-bottom: 20px;
}

.form-group{
    margin-top: 10px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: bold;
}

.form-group input {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-family: 'Manrope', 'Inter', sans-serif;
    outline: unset;
    margin-bottom: -5px;
}


input:focus-visible {
    outline: 2px solid rgba(255, 186, 8, 1);
    border: none;
}

.create_acct{
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    background: rgba(255, 186, 8, 1);
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin: 20px 0px;
}

.signup-image img {
    width: 100%;
    max-width: 600px;
    height: auto;
    max-height: 100vh;
}

#account {
    color: #21A60B;
}

.login-page {
    /* margin-top: 50px; */
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100vh;
}

.image-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.image-container img {
    width: 100%;
    max-width: 600px;
    height: auto;
    max-height: 80vh;
} 

.error{
    color: red;
    font-size: 12px;
    font-style: italic;
}

#name{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

#name div:first-of-type{
    margin-right: 2rem;
}
@media screen and (max-width: 768px) {
    .signup-container {
        margin-top: unset;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 90vh;
    }

    .login-page{
        height: 70vh;
    }
    .signup-content {
        padding: 0px 20px 20px 20px;
        width: 80vw;
        max-width: unset;
    }

    .signup-content h2 {
        font-size: 20px;
    }

    .signup-content p {
        font-size: 12px;
    }

    .form-group input {
        font-size: 12px;
    }

    .form-group:last-of-type {
        margin-bottom: 15px;
    }

    button[type="submit"] {
        font-size: 14px;
    }

    .signup-image img, .image-container img{
        display: none;
        width: 100%;
        max-width: 300px;
        height: auto;
        max-height: 200px;
        margin-top: 20px;
    }
}

input::-ms-reveal{
    display: none;
}

.icons8-eye {
    display: inline-block;
    position: absolute;
    right: 0px;
    bottom: 2px;
    width: 25px;
    height: 25px;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC4ElEQVR4nO2Y32vPURjHX7NNC/ObFil3wxab/AGU4sLPC7mYH1vNDbnyIxeSwtWE/Cr5UdsKJYrSyG9S3Ci0UrJyqRjhAttMz3q+9ez0Mc9Z332/Ls6rTn07n/N+zvP5nOc85zlfSCQSiUQiwWxgE3ASuAu8A7qBXm3y+y1wBzgONACziv3dZgJ7gZdA/zDbC2APUFVIx2uAS/pl+/PUfgFtwJyRdHwacBboy3DgB3AL2AesAOYCk4BSoEx/y4uvAvZrKP3MsCMf5TQwOd/OrwU+Zkwo4dMEVA7D5gSgGejMsPsBWJkPx8t004UT3AeW5mMCoARYDjzOmOeIruKwqNSltgYlmzQyMpQAW4AvwZwdwJhYY+OBp4GhmwXKFjOA28Hcj4BxXgOjNZdbA2ecS1mq+0Uyyhvguzb53QqsAUY5Q/d84IMkiXLPC5wLhJJZPCwBXjtS5itgsTOkDgRayVBD0hAIWpzObwd6IvJ+j2o8HAu06/82UOL7c7BknrBpDCb4pLm+HhirrV77uoOxm53hZENabEzPGnjRDOrSw+dfVOshltPdA6YMMX6qjrEHYLVjHrH53uhkjw1iAfDbDJCN6OGa0TwDKhwaGfPc6K4651pnNFIN1NqHV8zDB06DVaaskBJgHn5qTC3VF5Ge7WF32ebd3AaUVVjoNNYcHDaxiCanF1seFgUF4MCL7zKdDyMcaDE6sRGLnfdwhM6uwg7peGI6YsqEVqPbGO8/G4y+PULXZHTyMgMnZS6OJUt4OWEMbYv3n61GLze5mLI+t3++ScdB4KueejHsNg5cIB7R5PRiK4ZD6nysz4OoCw4Xqe+9TAwOtTqKhL2MSEh5OWV0nRSR1UFpsDMy+/TrNbOotAcOXQfmZ4yT0/5GMLaN/4CKjJtbrp7q0NaV8Vw0ntKjIEjFeNT5V0uv3nVF899RoyFly3Kbqdoia6aiUa7V4jJttd7rYCKRSCQSiQSF4w/T2VWvRib4dQAAAABJRU5ErkJggg==') 50% 50% no-repeat;
    background-size: 100%;
}

.password-icon{
    position: relative;
}
  