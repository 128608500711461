/* Header */
.header {
    /* position: fixed;
    top: 0;
    left: 0; */
    width: 100%;
    background-color: white;
    z-index: 999;
}

.container_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}
.container-img{
    width: 20%;
}

.container-img img {
    width: 80%;
}



.sub-container {
    display: flex;
    align-items: center;
}

.hamburger {
    display: none;
}


.header-nav {
    display: flex;
    justify-content: center;
    width: 60%;
}

.third-child{
    width: 20%;
    display: flex;
    justify-content: flex-end;
}

.header-nav ul {
    display: flex;
    gap: 2.5rem;
    list-style-type: none;
    margin: 0;
    padding: 0;
}


.header-nav a {
    text-decoration: none;
    color: #1F1E1E;
    font-weight: 600;
}

.container_box button {
    padding: 10px 20px;
    background-color: rgba(255, 186, 8, 1);
    color: #1F1E1E;
    font-weight: 600;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}

@media (max-width: 768px) {
    .container_box {
        padding: 10px;
    }

    .header-nav {
        display: none;
        margin-left: unset;
    }

    .third-child{
        display: none;
    }

    .mobile .header-nav {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        background-color: white;
        text-align: center;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s ease-in-out;
    }

    .mobile .header-nav.open {
        opacity: 1;
        pointer-events: all;
    }

    .mobile .header-nav ul {
        position: absolute;
        right: 5px;
        top: 50px;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.87) 50%, rgba(255, 185, 8, 0.685) 100%);
        width: 40vw;
        min-height: 88svh;
        flex-direction: column;
        gap: 1.5rem;
        z-index: 999;
        padding: 20px;
        transform: translateY(-10%);
        transition: transform 0.3s ease-in-out;
    }

    .mobile .header-nav ul.show {
        transform: translateY(0);
    }

    .mobile .header-nav ul li {
        margin: 20px 0;
        text-align: start;
        border-bottom: 1px solid rgba(33, 166, 11, 0.1);
    }

    .mobile .header-nav ul h3 {
        margin: 20px 0;
        text-align: center;
        color: rgba(33, 166, 11, 1);
    }

    .mobile .header-nav ul li:last-child {
        border-bottom: none;
    }

    .mobile .header-nav ul li a {
        background: linear-gradient(to right, #21A60B, rgba(255, 185, 8, 0.685));
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 16px;
        font-weight: 400;
        text-decoration: none;
    }

    .mobile .sub-container {
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .container_box button {
        display: none;
    }

    .hamburger {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 20px;
        padding: 5px;
        cursor: pointer;
    }

    .hamburger span {
        display: block;
        width: 25px;
        height: 3px;
        background-color: black;
        border-radius: 10px;
    }

    .container-img{
        width: 50%;
    }

    .container-img img {
        width: 100%;
    }
}

.profile-circle {
    width: 33px;
    height: 33px;
    border-radius: 50%; 
    background-color: #e0e0e0; 
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px; 
}


.profile-tab{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}

/* Hero */

.hero {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    /* margin-top: 80px; */
}

.container1 {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
}

.hero-content {
    max-width: 500px;
    margin: 0 auto;
    padding: 0 15px;
    color: rgba(31, 30, 30, 1);
    ;
}

.hero-content h3 {
    font-size: 2.5rem;
    font-weight: 700;
    color: #333;
    line-height: 1.2;
}

.hero-content h4 {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.2;
}

.hero-content p {
    margin: 10px auto;
    width: 350px;
    font-size: 1rem;
    font-weight: 500;
    color: #333;
    line-height: 1.5;
}

.hero-buttons {
    margin: 30px 0;
}

.hero-buttons button {
    margin: 0 15px;
    padding: 10px 30px;
    font-size: 12px;
    font-weight: 600;
    color: #1F1E1E;
    background-color: rgba(255, 186, 8, 1);
    border: none;
    border-radius: 36px;
    cursor: pointer;
}

#trial {
    background-color: #1F1E1E;
    color: white;
}

.hero-buttons button:hover {
    background-color: rgba(255, 185, 8, 0.685)
}

#trial:hover {
    background-color: #1f1e1e75;
}

.container1 img {
    max-width: 80%;
    min-height: 400px;
}

.container1 #left,
.container1 #right {
    position: absolute;
    width: 10%;
}

#left {
    left: 0;
}

#right {
    right: 0;
}


.mobile{
    display: none;
}
@media screen and (max-width: 768px) {
    .desktop{
        display: none;
    }

    .mobile{
        display: block;
    }

    .profile-tab{
        justify-content: end;
    }
    .hero {
        height: auto;
        margin-top: 120px;
    }

    .hero-content {
        max-width: 100%;
    }

    .hero-content h3 {
        font-size: 28px;
    }

    .hero-content p {
        font-size: 16px;
    }

    .container1 img {
        display: none;
    }
}

/* Partner */
.partners {
    padding: 30px 0 10px 0;
}

.container2 {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container2 h2 {
    margin-bottom: 30px;
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center;
}

.partner-logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.partner-logos img {
    margin: 0 20px;
    max-width: 100px;
}


@media screen and (max-width: 768px) {
    .partner-logos img {
        max-width: 100px;
    }
}

/* Features */

.features {
    padding: 80px 0;
    background: rgba(33, 166, 11, 0.1);
}

.features .container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.features .section-heading {
    font-size: 28px;
    margin-bottom: 40px;
}

.features .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly
}

.features .card {
    padding-top: 10px;
    background-color: #ffffff;
    width: calc(33.33% - 130px);
    /* margin-bottom: 40px; */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    overflow: hidden;
    transition: transform 0.2s ease-in-out;
}

.card:hover {
    transform: translateY(-10px);
}

.features .card img {
    width: 30%;
    height: auto;
    border-radius: 4px 4px 0 0;
    display: block;
    margin: 0 auto;
}


.features .card-heading {
    font-size: 18px;
    font-weight: bold;
    padding: 0px 20px;
}

.features .card-text {
    font-size: 12px;
    padding: 0px 20px 10px 20px;
}

@media only screen and (max-width: 830px) {
    .features {
        padding: 50px 0;
    }

    .features .section-heading {
        font-size: 24px;
        margin-bottom: 30px;
    }

    .features .card {
        width: 70%;
        margin-bottom: 30px;
    }


    .features .card img {
        width: 50%;
    }

    .features .card-heading {
        font-size: 16px;
        padding: 0px 10px;
    }

    .features .card-text {
        font-size: 12px;
        padding: 0px 10px 10px 10px;
    }
}


/* Easy-to-use */
.easy-to-use {
    background-color: #fff;
    padding: 50px 20px;
}

.easy-to-use .container {
    padding: 0px 60px;
}

.easy-to-use .section-heading {
    width: max-content;
    text-align: start;
    font-size: 20px;
    font-weight: 600;
    padding: 10px;
    margin-bottom: 30px;
    border: 2px solid #21A60B;
    border-radius: 8px;
}

.easy-to-use .card-container {
    border: 1px solid #21A60B;
    border-radius: 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 40px 20px;
}

.easy-to-use .card {
    background-color: #f2f2f2;
    text-align: center;
    padding: 10px;
    background: #FFFFFF;
    box-shadow: -2px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    width: 70%;
    margin: 0 auto;
}

.easy-to-use .card-group {
    position: relative;
}

.easy-to-use .card .icon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.easy-to-use .card .icon img {
    width: 60%;
}

.easy-to-use .card-heading {
    font-size: 18px;
}

.easy-to-use .card-text {
    margin-top: 10px;
    font-size: 12px;
    text-align: center;
    color: #666;
    line-height: 1.5;
}

.easy-to-use .card:hover {
    transform: unset;
}

/* Extra small devices (phones) */
@media only screen and (max-width: 576px) {
    .easy-to-use .card-group {
        display: block;
        margin-bottom: 40px;
    }

    .easy-to-use .arrow {
        display: none;
    }

    .easy-to-use .section-heading {
        font-size: 14px;
        font-weight: 600;
        padding: 5px;
    }
}

/* Small devices (tablets) */
@media only screen and (min-width: 577px) and (max-width: 768px) {
    .easy-to-use .card-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }

    .easy-to-use .card-group {
        width: 30%;
        margin-bottom: 0;
    }

    .easy-to-use .arrow {
        width: 5%;
        display: block;
        text-align: center;
    }

    .easy-to-use .card-group:nth-child(2n+1) .arrow {
        display: none;
    }
}

/* Medium devices (desktops) */
@media only screen and (min-width: 769px) {
    .easy-to-use .card-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .easy-to-use .card-group {
        width: 18%;
        margin-bottom: 0;
    }

    .easy-to-use .arrow {
        width: 10%;
        display: block;
    }

    .easy-to-use .card-group:nth-child(2n+1) .arrow {
        display: none;
    }
}

/* About us */
.about-us {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
    text-align: start;
    background: rgba(255, 186, 8, 0.25);
    border-radius: 32px;
}

.about-text {
    max-width: 500px;
    margin-bottom: 50px;
}

.about-text h2 {
    font-size: 40px;
    margin-bottom: 20px;
}

.about-text p {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 20px;
}

.about-image {
    max-width: 600px;
}

.about-image img {
    max-width: 100%;
}

.about-us .get-started-btn {
    display: inline-block;
    background-color: #1F1E1E;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    padding: 12px 40px;
    border-radius: 50px;
    text-decoration: none;
    transition: background-color 0.3s ease-in-out;
}

.about-us .get-started-btn:hover {
    background-color: #1f1e1eda;
}

/* Media Query for screens smaller than 768px */
@media (max-width: 768px) {
    .about-us {
        padding: 50px 20px;
        border-radius: unset;
    }

    .about-image img {
        display: none;
    }


    .about-text {
        margin-bottom: 30px;
    }

    .about-text h2 {
        font-size: 32px;
        margin-bottom: 10px;
    }

    .about-text p {
        font-size: 16px;
        margin-bottom: 10px;
    }
}

/* Resource */
/* Styling for the Resources component */

.resources-container {
    padding: 50px 0;
}

.resources-container h2 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 50px;
}

.resources-container .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.resources-container .card {
    margin: 20px;
    border-radius: 10px;
    overflow: hidden;
    width: 200px;
}

.resources-container .card img {
    width: 100%;
    height: auto;
}

.resources-container .card-text a {
    font-size: 0.8rem;
    line-height: 1.5;
    text-decoration: none;
}

.resources-container .card-text a:hover, .resources-container .card-text a:focus, .resources-container .card-text a:active{
    color: rgba(224, 148, 35, 0.959);
}

/* Media queries for responsive design */

@media only screen and (max-width: 768px) {
    .resources-container .card {
        width: calc(50% - 40px);
    }
}

@media only screen and (max-width: 480px) {
    .resources-container .card {
        width: calc(100% - 40px);
    }
}

/* Faq */

/* Mobile first styles */
.faq-section {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px;
    font-size: 16px;
    color: #444;
}

.faq-section h2 {
    width: max-content;
    margin: 0 auto;
    margin-bottom: 15px;
}

.faq-item {
    margin-bottom: 10px;
    cursor: pointer;
}

.faq-item h4 {
    margin: 0;
    padding: 15px 20px;
    font-size: 16px;
}

.accordion {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;

}

.faq-answer {
    padding: 20px;
    background-color: #f9f9f9;
    font-size: 12px;
    font-weight: 400;
    word-spacing: 1;
}

@media (max-width: 600px) {
    .faq-item h4 {
        font-size: 20px;
    }

    .faq-answer {
        padding: 10px;
    }

    .faq-section h2 {
        font-size: 14px;
    }
}

/* Newsletter */

.news {
    padding: 50px 0;
    background-color: rgba(255, 186, 8, 0.25);
    text-align: center;
}

.news-container {
    max-width: 960px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.news-container .left {
    flex-basis: 60%;
    text-align: left;
    margin-bottom: 20px;
}

.news-container .right {
    flex-basis: 35%;
}

.news-container h3 {
    margin-bottom: 10px;
    font-size: 28px;
    font-weight: bold;
}

.news-container p {
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 1.5;
}

.news-container form {
    display: flex;
    justify-content: center;
    align-items: center;
}

.news-container input[type="email"] {
    padding: 10px;
    width: 70%;
    border-radius: 3px;
    border: 1px solid #ccc;
    font-size: 14px;
    font-family: 'Manrope';
}

#submit_button {
    background-color: #1F1E1E;
    border: none;
    color: #fff;
    font-size: 14px;
    padding: 10px 20px;
    border-radius: 3px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.news-container button[type="submit"]:hover {
    background-color: #1f1e1eda;
    transition: all 0.2s ease-in-out;
}

.news-container input[type="email"]:focus-visible {
    outline: 2px solid rgba(255, 185, 8, 0.685);
    border: none;

}

@media only screen and (max-width: 767px) {
    .news-container {
        flex-direction: column;
        align-items: center;
    }

    .news-container .left {
        flex-basis: 100%;
        text-align: center;
    }

    .news-container .right {
        flex-basis: 100%;
    }

    .news-container input[type="email"] {
        width: 100%;
        margin-bottom: 10px;
    }
}

/* Footer */
.footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 40px 20px;
    border-top: 1px solid #1F1E1E;
}

.footer h4 {
    font-size: 16px;
    margin-bottom: 20px;
}

.footer ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.footer li {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.footer li img {
    margin-right: 10px;
    width: 20px;
}

.footer a {
    color: #1F1E1E;
    text-decoration: none;
}

.footer a:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .footer {
        flex-direction: column;
        text-align: center;
    }

    .footer div {
        margin-bottom: 30px;
    }

    .footer-menu {
        margin-top: 30px;
    }
}

/* BMI component */
.Bmi-content {
    width: 100%;
    /* margin: 0 auto; */
}

.Bmi_weight,
.Bmi_height {
    width: 500px;
    margin: 0 auto;
}

/* bmi-content.css */

.Bmi-content {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: space-around;
}


.Bmi_weight,
.Bmi_height {
    margin-bottom: 16px;
}

.Bmi_weight label,
.Bmi_height label {
    margin-bottom: 8px;
}

.Bmi_weight input,
.Bmi_height input {
    padding: 8px;
    width: 500px;
    height: 25px;
    font-family: 'Manrope', 'Inter', sans-serif;
    font-style: italic;
    margin-bottom: 20px;
    border-radius: 8px;
    border: 1px solid rgba(94, 94, 94, 1);
}

.Bmi_button {
    margin-top: 20px;
    width: 230px;
    padding: 16px 20px;
    background-color: rgba(255, 186, 8, 1);
    border-radius: 12px;
    color: rgb(0, 0, 0);
    border: none;
    cursor: pointer;
    font-weight: 700;
}

.Bmi_button:hover {
    background-color: rgba(255, 185, 8, 0.747);
}

.Bmi_button:focus {
    outline: none;
}

/* Modal */

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background: rgba(224, 148, 35, 0.959);
    padding: 20px;
    border-radius: 5px;
    min-width: 300px;
    max-width: 400px;
    text-align: center;
  }
  
  .Bmi-result {
    margin-top: 0;
    background-color: rgba(255, 255, 255, 0.65);
    border: 1px solid #E09523;;
    border-radius: 15px;
    padding: 7px 15px;
    width: max-content;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  
  .modal-content h3{
    margin-bottom:20px;
  }

  .modal-content button {
    padding: 8px 16px;
    background-color: rgb(252, 236, 197);
    border: none;
    border-radius: 3px;
    cursor: pointer
  }
  
  .search-container {
  margin: 20px;
  padding: 10px;
 
}


.search-input {
  width: 100%;
  padding: 5px;
  position: relative;
  z-index: 2;
}

.results-list {
  list-style-type: none;
  padding-left: 0;
  height: 200px;
  overflow-y: scroll;
  margin-top:0.5rem;
}

.results-list::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.results-list::-webkit-scrollbar-track {
  background: rgba(255, 185, 8, 0.685);
  border-radius: 5px; /* Track (background) color */
}

.results-list::-webkit-scrollbar-thumb {
  background: #888; /* Scrollbar thumb color */
  border-radius: 4px; /* Rounded corners */
}

.results-list::-webkit-scrollbar-thumb:hover {
  background: #555; /* Hovered scrollbar thumb color */
}


.result-item {
  margin: 5px 0;
  border-bottom: 1px solid #ccc;
  padding: 5px;
}

.result-item:hover{
    background-color: rgba(182, 182, 182, 0.199);
}

input[type="number"] {
    width: 95%;

}

@media (max-width: 600px) {
    .Bmi_weight,
    .Bmi_height {
        width: 100%;
    }
}

.data-source{
    font-size: 12px;
    font-weight: 300;
    font-style: italic;
    padding: 0px 20px;
}

@media screen and (max-width: 768px) {
    .search-container {
        padding: 0px;
       
    }
    
    
    .Bmi-content {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        gap: 2rem;
    }

    .bmi_body{
        width: 90%;

    }
    .bmi-proceed{
        width: 100%;
    }
    
      
}