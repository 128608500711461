.search-form {
    max-width: 100%;
    margin-top: 30px;
}

.tab-page .form-group {
    width: 500px;
    margin: 0 auto;
    margin-bottom: 20px;
}

label {
    display: block;
    font-weight: bold;
}

select {
    width: 100%;
    padding: 10px 25px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-family: 'Manrope', 'Inter', sans-serif;;
}

.proceed {
    display: flex;
    align-items: center;
    justify-content: center;
}

.proceed button {
    width: 395px;
    padding: 18px 40px;
    border: none;
    background-color: rgba(255, 186, 8, 1);
    cursor: pointer;
    border-radius: 8px;
    font-weight: 700;
    font-size: 1rem;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.proceed button img {
    margin-left: 5px;
}


select option {
    font-style: italic;
    color: #555;
}

select option:hover {
    background-color: #f5f5f5;
}

select option:selected {
    font-weight: bold;
}



@media (max-width: 768px) {
    .form-group, .search-form {
        width: 100% !important;
    }
}

@media (max-width: 480px) {
    .form-group img {
        display: none;
    }


}

.nutrient-error{
    font-size: 15px;
    text-align: center;
}

#total{
    margin-block: 1rem;
}