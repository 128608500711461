*{
  margin: 0;
}

body {
  margin: 0;
  font-family:'Manrope', 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
}

body::-webkit-scrollbar {
	display: none;
}

button{
  font-family: 'Inter', 'Manrope', 'sans-serif';
}